import type { AppSpecificConfiguration } from 'owa-msaljs';
import { getOrigin } from 'owa-url/lib/getOrigin';
import { isBootFeatureEnabled } from 'owa-metatags';

const msalConfiguration: AppSpecificConfiguration = {
    auth: {
        clientId: '472dd75b-cdf2-42a0-9fec-b86cefca8135',
        redirectUri: `${getOrigin()}/places/`,
    },
    cache: {
        cacheLocation: isBootFeatureEnabled('auth-msaljs-places-sessionStorage')
            ? 'sessionStorage'
            : 'localStorage',
    },
};

export default msalConfiguration;
