import { getBootCustomData, getBootTimings } from 'owa-performance';
import getUserSessionCount from 'owa-session-store/lib/selectors/getUserSessionCount';

import type { PerformanceCoreDatapoint, PerformanceDatapoint } from 'owa-analytics';

export function addBootCustomData(datapoint: PerformanceDatapoint | PerformanceCoreDatapoint) {
    const bootTimings = JSON.parse(getBootTimings());
    const isOfflineStartupData = bootTimings.offsd_e
        ? bootTimings.offsd_e < bootTimings.sd_e
        : false;

    // Add User session metrics
    datapoint.addCustomData({
        userSessionCount: getUserSessionCount(),
    });

    // Add Bootstrap timings
    datapoint.addCustomData({
        startupDataStart: isOfflineStartupData ? bootTimings.offsd_s : bootTimings.sd_s,
        startupDataEnd: isOfflineStartupData ? bootTimings.offsd_e : bootTimings.sd_e,
        bootJavaScriptStart: bootTimings.mjs_s,
        bootJavaScriptEnd: bootTimings.mjs_e,
        renderStart: bootTimings.ren_s,
        promisesLayerStart: bootTimings.sp_s,
        promisesLayerEnd: bootTimings.sp_e,
    });

    // Add MetaOS Host information
    const bootCustomData = getBootCustomData();
    datapoint.addCustomData({
        hostSessionId: bootCustomData.HostSessionId,
        hostUserClickTime: bootCustomData.HubLoadData?.userClickTime,
        hostRing: bootCustomData.MetaOsAppRingId,
        hostName: bootCustomData.MetaOsAppHostName,
    });

    if (bootCustomData.MetaOsAppLoaded) {
        const startTime = datapoint.getStartTime();
        const metaOsAppLoaded = startTime ? bootCustomData.MetaOsAppLoaded - startTime : undefined;

        datapoint.addCustomData({
            metaOsAppLoaded,
        });
    }
}
