import getEntryPoint from './getEntryPoint';

const getOrigin = (metaOsRelativeDeepLink?: string) => {
    const entryPoint = getEntryPoint(metaOsRelativeDeepLink);
    switch (entryPoint) {
        case 'emailWelcomeToPlaces':
            return 'WelcomeEmail';
        case 'emailConfirmationReminder':
            return 'ConfirmationEmail';
        case 'inviteEmail':
            return 'InviteEmail';
        case 'TeamsMessageExtension':
            return 'TeamsMessageExtension';
        case 'calendarPeekOthersInOffice':
            return 'CalendarPeekOthersInOffice';
        case 'owawhlcalendarsurface':
            return 'OwaWhlCalendarSurface';
        case 'calendarTodaysOthersInOffice':
            return 'CalendarTodaysOthersInOffice';
        default:
            return 'Other';
    }
};

export default getOrigin;
