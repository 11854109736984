import { getQueryStringParameter, nodeParseQueryString } from 'owa-querystring';
import { isNullOrWhiteSpace } from 'owa-string-utils';

export default function getEntryPoint(qspOverride?: string): string | null {
    // Check QSP override first. this is split out in case nodeParseQueryString(qspOverride)?.ep returns empty, then we'll fallback to window QSP.
    const qspEpIndex = qspOverride?.indexOf('ep') || -1;
    const qspEpParamString =
        qspOverride && qspEpIndex > -1 ? qspOverride.slice(qspEpIndex) : qspOverride;

    const qspoverrideEntryPoint = qspEpParamString
        ? nodeParseQueryString(qspEpParamString)?.ep
        : undefined;
    const entryPoint = qspoverrideEntryPoint
        ? qspoverrideEntryPoint
        : getQueryStringParameter('ep');
    if (isNullOrWhiteSpace(entryPoint)) {
        return null;
    }
    return entryPoint;
}
