import { logUsage } from 'owa-analytics';
import { scrubForPii } from 'owa-config';
import getEntryPoint from './utils/getEntryPoint';

import type { CustomData, UnifiedEvent } from 'owa-analytics-types';

/**
 * Unified Telemetry supported events for places
 */
export type PlacesSupportedEventName =
    | 'PlacesConfirmWorkPlan'
    | 'PlacesSetWorkPlan'
    | 'PlacesViewAdminPortal'
    | 'PlacesViewWorkPlan'
    | 'PlacesAppLoad'
    | 'PlacesWelcomeShown'
    | 'PlacesWelcomeLocationSelected'
    | 'PlacesOnboardingProgressClicked'
    | 'PlacesWeeklyWorkRoutineShown'
    | 'PlacesViewConnections'
    | 'PlacesConnectionsEdited'
    | 'PlacesSetWorkRoutine'
    | 'PlacesAdminAction'
    | 'PlacesSetDayForAgendaView'
    | 'PlacesShareWorkPlan'
    | 'PlacesComponentViewChange'
    | 'PlacesNavigationClick'
    | 'PlacesOpenPlacesFinder'
    | 'QuickBookModalAction'
    | 'QuickBookLoadedInPeek'
    | 'DeskBookLoadedInPeek'
    | 'MapInteraction'
    | 'PlacesUserSettings'
    | 'PlacesWorkGroupManagement'
    | 'PlacesWorkGuidanceManagement'
    | 'PlacesWorkGuidanceInteraction'
    | 'PlacesNoWorkDaysStateAction'
    | 'PlacesTriggerWorkPlanOnboarding'
    | 'PlacesExploreCollaboratorsView'
    | 'PlacesPageLoad'
    | 'PlacesExploreCardInteraction'
    | 'PlacesPeopleInteraction'
    | 'PlacesWorkGroupsInteraction'
    | 'PlacesTimestripSelectDate'
    | 'PlaceWorkplaceCopilotFeedback'
    | 'PlacesFinderFirstInteraction'
    | 'PlacesClosePlacesFinder'
    | 'PlacesUserHasInvalidTimeZone'
    | 'PlacesBuildingNavigation'
    | 'PlacesGlobalActionButton'
    | 'HybridGuidanceViewWorkGroup'
    | 'RoomFinderSelectPlace';

type SupportedPlacesUT = Extract<
    UnifiedEvent,
    {
        eventName: PlacesSupportedEventName;
    }
>;

/**
 * Logs Unified Telemetry event for places
 */
export default function logPlacesEvent(
    unifiedTelemetry: SupportedPlacesUT,
    customData?: CustomData,
    metaOsRelativeDeepLink?: string
) {
    // We have to check for ActionFailureInfo manually because it is not a part of some events args type
    if (unifiedTelemetry.data && 'ActionFailureInfo' in unifiedTelemetry.data) {
        unifiedTelemetry.data.ActionFailureInfo = scrubForPii(
            JSON.stringify(unifiedTelemetry.data.ActionFailureInfo)
        );
    }
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
    logUsage(
        unifiedTelemetry.eventName,
        { entryPoint: getEntryPoint(metaOsRelativeDeepLink), ...customData },
        { unifiedTelemetry }
    );
}
