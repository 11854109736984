import { placesFwkPageLoadAction } from 'places-fwk-actions';
import { orchestrator } from 'satcheljs';
import { PlacesPageLoaderE2EStart, PlacesPageLoaderE2EComplete } from '../placesPageLoaderE2E';
import { isPlacePageLoaderType } from '../types/PlacesPageLoaderType';

// Orchestrator for Places Page Loaders
// perf and telemetry markers
orchestrator(placesFwkPageLoadAction, ({ page, status, errorMessage }) => {
    if (!isPlacePageLoaderType(page)) {
        return;
    }

    // UT Logging
    switch (status) {
        case 'start':
            PlacesPageLoaderE2EStart(page);
            break;
        case 'end':
            PlacesPageLoaderE2EComplete(page, !errorMessage, errorMessage);
            break;
    }
});
