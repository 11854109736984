import { action } from 'satcheljs';

import type {
    PlacesAnalyticsAppLoadActionScenarios,
    PlacesAnalyticsPerfEventNames,
    PlacesAnalyticsTabLoadActionScenarios,
    PlacesAnalyticsTabIds,
    PlacesLoaderSources,
    PlacesRootComponentSources,
} from '../types';
import type { TraceErrorObject } from 'owa-trace';

/** This action is called when specific components are mounted that is used for telemetry purposes. */
export const placesFwkRootComponentMountedAction = action(
    'PLACES_FWK_ACTION_ROOT_COMPONENT_MOUNTED',
    (rootComponentName: PlacesRootComponentSources, errorObject?: TraceErrorObject) => ({
        rootComponentName,
        errorObject,
    })
);

/** This action is called when a check point is reached  at the route loaders */
export const placesLoaderAction = action(
    'PlacesLoaderAction',
    (loaderName: PlacesLoaderSources) => ({
        loaderName,
    })
);

/** This action is called when a page completes loading
 * @param page - The page that has completed loading or specific route
 * @param status - Whether the page loading has started or ended
 * @param result - The status of the page load (Success, Empty, Error, NoAccess, NotFound) - Or anything else required for logging/perf
 * @param errorMessage - Additional information about the failure
 */
export const placesFwkPageLoadAction = action(
    'PlacesFwkPageLoadAction',
    (page: string, status: 'start' | 'end', result: string, errorMessage?: string) => ({
        page,
        status,
        result,
        errorMessage,
    })
);

export const placesAnalyticsAppLoadAction = action(
    'PlacesAnalyticsAppLoadAction',
    ({
        scenario,
        customData,
        errorSource,
        errorObject,
    }: {
        scenario: PlacesAnalyticsAppLoadActionScenarios;
        customData?: any;
        errorSource?: string;
        errorObject?: TraceErrorObject;
    }) => ({
        scenario,
        errorSource,
        customData,
        errorObject,
    })
);

export const placesAnalyticsTabLoadAction = action(
    'PlacesAnalyticsTabLoadAction',
    ({
        scenario,
        tabId,
        prevTabId,
        customData,
        errorSource,
        errorObject,
    }: {
        scenario: PlacesAnalyticsTabLoadActionScenarios;
        tabId: PlacesAnalyticsTabIds;
        prevTabId?: PlacesAnalyticsTabIds;
        customData?: any;
        errorSource?: string;
        errorObject?: TraceErrorObject;
    }) => ({
        scenario,
        tabId,
        prevTabId,
        errorSource,
        customData,
        errorObject,
    })
);

export const cancelPlacesAnalyticsPerfEventsAction = action(
    'CancelPlacesAnalyticsPerfEventsAction',
    ({
        scenario,
        eventsToCancel,
    }: {
        scenario: string; // to be updated
        eventsToCancel: (PlacesAnalyticsPerfEventNames | 'All')[];
    }) => ({
        scenario,
        eventsToCancel,
    })
);
