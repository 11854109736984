import { logGreyError } from 'owa-analytics';
import { PassiveMonitoringAriaTenantToken } from 'owa-analytics-start';
import { runAfterInitialRender } from 'owa-bundling-light/lib/utils/delayLoad';
import { GovernPriority } from 'owa-client-types/lib/GovernPriority';
import { lazySaveOfflineSessionData } from 'owa-data-worker-client';
import {
    getOfflineSessionData,
    initializeWorker,
} from 'owa-data-worker-client/lib/actions/getOfflineSessionData';
import { networkRace, type RaceParticipant } from 'owa-offline/lib/networkRace';
import { addBootCustomData } from 'owa-performance';
import getSessionData from 'owa-shared-start/lib/getSessionData';
import { start } from 'owa-start';
import { lazyBootstrap } from './lazyBootFunctions';
import { onFatalBootError } from './onFatalBootError';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import msalConfiguration from 'hybridspace-common/lib/constants/msalConfiguration';

const app = 'Places';

start({
    app,
    loadBpos: true,
    startupAriaToken: PassiveMonitoringAriaTenantToken(), // OWA Passive Monitoring
    msalConfiguration,
    runBeforeStart: async () => {
        initializeWorker();
    },
    bootstrap: lazyBootstrap,
    onFatalBootError,

    overrideBootPromises: () =>
        networkRace(
            'SessionData',
            () => getSessionData(),
            () => getOfflineSessionData(app) as Promise<SessionData>,
            (networkSD, offlineSD, winner) => {
                if (winner === 2) {
                    runAfterInitialRender(
                        () =>
                            lazySaveOfflineSessionData
                                .importAndExecute(networkSD, offlineSD)
                                .catch(e => logGreyError('PlacesSaveOfflineSessionDataError', e)),
                        GovernPriority.Default
                    );
                }
            }
        ).then(({ value, customData }) => {
            addBootCustomData('SessionDataNetworkRace', customData);
            return value;
        }),
});
